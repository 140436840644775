import React, { useState, useEffect } from 'react';
import { Button, Space, Row, Col, Form, Input, Select, Modal, message, InputNumber } from 'antd';
import { getAllContractors, addIntakeFormContractor } from '../../actions';
import { DollarFormatter, DollarParser } from '../../helpers';
import responseErrorService from '../../utilities/responseErrorHandling';
import TextArea from 'antd/lib/input/TextArea';

const { Option } = Select;

const AddIntakeFormContractorModal = ({
  isModalVisible,
  setIsModalVisible,
  intakeFormId,
  setExistingContractors = null,
  doRefreshIntakeFormContractors = null,
}) => {
  const [form] = Form.useForm();
  const [contractors, setContractors] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (isModalVisible) {
      fetchContractors();
    }
  }, [isModalVisible]);

  const fetchContractors = () => {
    getAllContractors()
      .then(response => {
        const { data } = response;
        setContractors(data);
      })
      .catch(err => {
        responseErrorService.handle(err);
      });
  };

  const onSubmit = (values) => {
    const payload = {...values, intake_form_id: intakeFormId}
    setIsSaving(true);
    addIntakeFormContractor(payload)
      .then(() => {
        message.success("Intake Form Contractor added successfully!");
        setIsSaving(false);
        form.resetFields();
        setIsModalVisible(false);
        if(doRefreshIntakeFormContractors && setExistingContractors){
          setExistingContractors([]);
          doRefreshIntakeFormContractors(prev => prev + 1);
        }
      })
      .catch((err) => {
        responseErrorService.handle(err);
        setIsSaving(false);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  return (
    <Modal
      destroyOnClose={true}
      width={700}
      title="Add Contractor in Job"
      okText={"Save"}
      cancelText="Close"
      visible={isModalVisible}
      onOk={() => form.submit()}
      onCancel={() => handleCancel()}
      confirmLoading={isSaving}
      >
      <Form 
        form={form} 
        layout="vertical"
        onFinish={(values) => onSubmit(values)}
      >
        <Form.Item
          name="contractor_id"
          label="Select Contractor"
          rules={[{ required: true, message: 'Please select a contractor!' }]}
        >
          <Select
            showSearch
            allowClear={true}
            style={{ width: "100%" }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Select a contractor"
          >
            {contractors.map((contractor) => (
              <Select.Option value={contractor.id} key={contractor.id}>
                {contractor.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="pay_amount"
          label="Payable Amount"
          rules={[{ required: true, message: 'Please enter the payable amount!' }]}
        >
          <InputNumber
            min={0} 
            step={0.01}
            precision={2}
            style={{ width: '100%' }} 
            formatter={DollarFormatter}
            parser={DollarParser} 
          />
        </Form.Item>

        <Form.Item
          name="paid_to_contractor"
          label="Paid Amount"
        >
          <InputNumber 
            min={0} 
            step={0.01}
            precision={2}
            style={{ width: '100%' }} 
            formatter={DollarFormatter}
            parser={DollarParser} 
          />
        </Form.Item>

        <Form.Item
          name="notes"  
          label="Notes"
        >
          <TextArea
            placeholder={`Enter any notes`}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddIntakeFormContractorModal;
