import React from "react";
import {
  HomeOutlined,
  ShopOutlined,
  ToolOutlined,
  TeamOutlined,
  DollarOutlined,
  SettingOutlined,
  FileOutlined,
  AuditOutlined,
  ContainerOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  CodepenOutlined,
  MessageOutlined,
  FormOutlined,
  EditOutlined,
  BankOutlined,
  ReconciliationOutlined,
  BlockOutlined,
  NotificationOutlined,
  RocketOutlined,
  ImportOutlined,
  DashboardOutlined,
  FieldTimeOutlined,
  NumberOutlined,
  ProfileOutlined,
  ExceptionOutlined,
  MoneyCollectOutlined,
  ContactsOutlined,
} from "@ant-design/icons";

import DashboardLayout from "./layouts/dashboard";
import Login from "./pages/login";
import RegisterPage from "./pages/register";
import NotFoundPage from "./pages/404Page";

const IntakeFormPageComponent = React.lazy(() =>
  import("./pages/intakeForms")
);
const GlassPickupPageComponent = React.lazy(() =>
  import("./pages/glassPickupManagement")
);
const ContractorComponent = React.lazy(() =>
  import("./pages/contractorManagement")
);
const SingleIntakeFormPageComponent = React.lazy(() =>
  import("./pages/intakeForm")
);
const HomePageComponent = React.lazy(() =>
  import("./pages/homePage")
);
const ShopLocationsPageComponent = React.lazy(() =>
  import("./pages/shopLocations")
);
const TintPricingPageComponent = React.lazy(() =>
  import("./pages/tintPricing")
);
const SettingsPageComponent = React.lazy(() =>
  import("./pages/settings")
);
const UserManagementPageComponent = React.lazy(() =>
  import("./pages/userManagement")
);
const InsuranceCarriersPageComponent = React.lazy(() =>
  import("./pages/insuranceCarriers")
);
const FileBrowserPageComponent = React.lazy(() =>
  import("./pages/fileBrowser")
);
const CallRailMessagesPageComponent = React.lazy(() =>
  import("./pages/callRailMessages")
);
const CallRailSingleConversationPageComponent = React.lazy(() =>
  import("./pages/callRailSingleConversation")
)
const PortalHealthStatusPage = React.lazy(() =>
  import("./pages/portalHealthStatus")
)
const ChatQuickResponsesPage = React.lazy(() =>
  import("./pages/chatQuickResponses")
)
const GlassAbbreviationsPage = React.lazy(() =>
  import("./pages/glassAbbreviations")
)
const TagsManagementPage = React.lazy(() =>
  import("./pages/tagsManagement")
)
const SquareInvoicesManagementPage = React.lazy(() =>
  import("./pages/squareInvoicesManagement")
)
const CallRailLogsPage = React.lazy(() =>
  import("./pages/callRailLogs")
)
const JobReviewsPage = React.lazy(() =>
  import("./pages/jobReviews")
)
const AccountAdminActionsPage = React.lazy(() =>
  import("./pages/accountAdminActions")
)
const SquarePaymentsManagementPage = React.lazy(() =>
  import("./pages/squarePaymentsManagement")
)
const SquareLocationsManagementPage = React.lazy(() =>
  import("./pages/squareLocationsManagement")
)
const VendorsManagementPage = React.lazy(() =>
  import("./pages/vendorsManagement")
)
const ExternalOrdersManagementPage = React.lazy(() =>
  import("./pages/externalOrdersManagement")
)
const CustomersManagementPage = React.lazy(() =>
  import("./pages/customersManagement")
)
const NotificationsManagementPage = React.lazy(() =>
  import("./pages/notificationsManagement")
)
const TechnicianJobsPage = React.lazy(() =>
  import("./pages/technicianJobs")
);
const AvailableTechniciansPage = React.lazy(() =>
  import("./pages/availableEmployees/availableTechnicians")
)
const AvailableCSRAgentsPage = React.lazy(() =>
  import("./pages/availableEmployees/availableCSRAgents")
)
const EGRLeadsPage = React.lazy(() =>
  import("./pages/egrLeads")
)
const CashLedgerManagementPage = React.lazy(() =>
  import("./pages/cashLedgerManagment")
)
const EGRLeadPage = React.lazy(() =>
  import("./pages/egrLead")
)
const PortalDashboardManagementPage = React.lazy(() =>
  import("./pages/portalDashboardManagement")
);
const NagsDetailsOverviewPage = React.lazy(() =>
  import("./pages/nagsDetailsOverviewPage")
);
const PriceSheetManagement = React.lazy(() =>
  import("./pages/priceSheetManagement")
);
const PriceSheetSingle = React.lazy(() =>
  import("./pages/priceSheetDetailsSingle")
);
const NotificationSystemInterface = React.lazy(() =>
  import("./pages/notificationSystemInterface")
);
const CallRailFormsPage = React.lazy(() =>
  import("./pages/callRailForms")
);
const PortalAppSettingsPage = React.lazy(() =>
  import("./pages/portalAppSettings")
);
const SixtEmailsPage = React.lazy(() =>
  import("./pages/sixtEmails")
);
const PartCancellationsPage = React.lazy(() =>
  import("./pages/partCancellations")
);

export const INTAKE_FORMS_ROUTE = "/intake-forms";

export const GLASS_PICKUP_ROUTE = "/glass-pickup";

export const CONTRACTOR_ROUTE = "/contractors";

export const TECHNICIAN_JOBS_ROUTE = "/technician-jobs";

export const CALLRAIL_LOGS_ROUTE = "/callrail-records";

export const CALLRAIL_MESSAGES_ROUTE = "/messages";

export const CALLRAIL_SINGLE_CONVERSATION_ROUTE = "/messages/:conversationId";

export const SINGLE_INTAKE_FORM_ROUTE = "/intake-form/:intakeFormId";

export const SHOP_LOCATIONS_API_PATH = "/shop-locations";

export const TINT_PRICINGS_API_PATH = "/tint-pricings";

export const FILE_BROWSER_API_PATH = "/file-browser";

export const INSURANCE_CARRIERS_API_PATH = "/insurance-carriers";

export const SETTINGS_API_PATH = "/settings";

export const VENDORS_API_PATH = "/vendors";

export const USER_MANAGEMENT_ROUTE = "/user-management";

export const PORTAL_HEALTH_ROUTE = "/portal-health";

export const CHAT_QUICK_RESPONSES_API_PATH = "/chat-quick-responses";

export const GLASS_ABBREVIATIONS_API_PATH = "/glass-abbreviations";

export const TAGS_API_PATH = "/tag-management";

export const SQUARE_INVOICES_API_PATH = "/square-invoices";

export const SQUARE_PAYMENTS_API_PATH = "/square-payments";

export const SQUARE_LOCATIONS_API_PATH = "/square-locations";

export const JOB_REVIEWS_API_PATH = "/job-reviews";

export const EXTERNAL_PART_ORDERS_API_PATH = "/parts-ordered";

export const ACCOUNT_ADMIN_ACTIONS_API_PATH = "/account-admin-actions";

export const CUSTOMERS_API_PATH = "/customers";

export const NOTIFICATIONS_API_PATH = "/notifications";

export const CASH_LEDGER_MANAGEMENT_API_PATH = "/cash-ledger-management";

export const AVAILABLE_TECHNICIANS_ROUTE = "/available-technicians";

export const AVAILABLE_CSR_AGENTS_ROUTE = "/available-csr-agents";

export const EGR_LEADS_ROUTE = "/egr-leads";

export const EGR_LEAD_ROUTE = "/egr-lead/:egrLeadId";

export const DASHBOARD_MANAGEMENT_ROUTE = "/dashboard-management";

export const NAGS_DETAILS_OVERVIEW_ROUTE = "/nags-details";

export const PRICE_SHEET_MANAGEMENT_ROUTE = "/price-sheet-management";

export const PRICE_SHEET_SINGLE_ROUTE = "/price-sheet/:priceSheetDetailsId";

export const NOTIFICATION_SYSTEM_INTERFACE_ROUTE = "/notification-system-interface";

export const CALLRAIL_FORMS_ROUTE = "/callrail-forms";

export const PORTAL_APP_SETTINGS_ROUTE = "/portal-app-settings";

export const SIXT_EMAILS_ROUTE = "/sixt-emails";

export const PART_CANCELLATIONS_ROUTE = "/part-cancellations";

export const INDEX_ROUTE = "/";
// ROUTES
export const NON_LOGIN_ROUTES = [
  {
    exact: true,
    name: "login",
    path: INDEX_ROUTE,
    component: Login,
  },
  {
    component: NotFoundPage,
  },
];

export const LOGGED_IN_ROUTES = [
  {
    name: "layout",
    path: INDEX_ROUTE,
    component: DashboardLayout,
    routes: [
      {
        exact: true,
        name: "home",
        path: INDEX_ROUTE,
        component: HomePageComponent,
      },
      {
        exact: true,
        name: "home",
        path: DASHBOARD_MANAGEMENT_ROUTE,
        component: PortalDashboardManagementPage,
      },
      {
        exact: true,
        name: "nags-details",
        path: NAGS_DETAILS_OVERVIEW_ROUTE,
        component: NagsDetailsOverviewPage,
      },
      {
        exact: true,
        name: "callrail-forms",
        path: CALLRAIL_FORMS_ROUTE,
        component: CallRailFormsPage,
      },
      {
        exact: true,
        name: "price-sheet-management",
        path: PRICE_SHEET_MANAGEMENT_ROUTE,
        component: PriceSheetManagement,
      },
      {
        exact: true,
        name: "price-sheet",
        path: PRICE_SHEET_SINGLE_ROUTE,
        component: PriceSheetSingle,
      },
      {
        exact: true,
        name: "intake-forms",
        path: INTAKE_FORMS_ROUTE,
        component: IntakeFormPageComponent,
      },
      {
        exact: true,
        name: "glass-pickup",
        path: GLASS_PICKUP_ROUTE,
        component: GlassPickupPageComponent,
      },
      {
        exact: true,
        name: "contractor",
        path: CONTRACTOR_ROUTE,
        component: ContractorComponent,
      },
      {
        exact: true,
        name: "egr-leads",
        path: EGR_LEADS_ROUTE,
        component: EGRLeadsPage,
      },
      {
        exact: true,
        name: "egr-lead",
        path: EGR_LEAD_ROUTE,
        component: EGRLeadPage,
      },
      {
        exact: true,
        name: "intake-form",
        path: SINGLE_INTAKE_FORM_ROUTE,
        component: SingleIntakeFormPageComponent,
      },
      {
        exact: true,
        name: "technician-jobs",
        path: TECHNICIAN_JOBS_ROUTE,
        component: TechnicianJobsPage,
      },
      {
        exact: true,
        name: "available-technicians",
        path: AVAILABLE_TECHNICIANS_ROUTE,
        component: AvailableTechniciansPage,
      },
      {
        exact: true,
        name: "available-csr-agents",
        path: AVAILABLE_CSR_AGENTS_ROUTE,
        component: AvailableCSRAgentsPage,
      },
      {
        exact: true,
        name: "cash-ledger-management",
        path: CASH_LEDGER_MANAGEMENT_API_PATH,
        component: CashLedgerManagementPage,
      },
      {
        exact: true,
        name: "notifications",
        path: NOTIFICATIONS_API_PATH,
        component: NotificationsManagementPage,
      },
      {
        exact: true,
        name: "callrail-messages",
        path: CALLRAIL_MESSAGES_ROUTE,
        component: CallRailMessagesPageComponent,
      },
      {
        exact: true,
        name: "callrail-single-conversation",
        path: CALLRAIL_SINGLE_CONVERSATION_ROUTE,
        component: CallRailSingleConversationPageComponent,
      },
      {
        exact: true,
        name: "shop-locations",
        path: SHOP_LOCATIONS_API_PATH,
        component: ShopLocationsPageComponent,
      },
      {
        exact: true,
        name: "tint-pricings",
        path: TINT_PRICINGS_API_PATH,
        component: TintPricingPageComponent,
      },
      {
        exact: true,
        name: "insurance-carriers",
        path: INSURANCE_CARRIERS_API_PATH,
        component: InsuranceCarriersPageComponent,
      },
      {
        exact: true,
        name: "chat-quick-responses",
        path: CHAT_QUICK_RESPONSES_API_PATH,
        component: ChatQuickResponsesPage,
      },
      {
        exact: true,
        name: "file-browser",
        path: FILE_BROWSER_API_PATH,
        component: FileBrowserPageComponent,
      },
      {
        exact: true,
        name: "settings",
        path: SETTINGS_API_PATH,
        component: SettingsPageComponent,
      },
      {
        exact: true,
        name: "account-admin-actions",
        path: ACCOUNT_ADMIN_ACTIONS_API_PATH,
        component: AccountAdminActionsPage,
      },
      {
        exact: true,
        name: "portal-health",
        path: PORTAL_HEALTH_ROUTE,
        component: PortalHealthStatusPage,
      },
      {
        exact: true,
        name: "customers",
        path: CUSTOMERS_API_PATH,
        component: CustomersManagementPage,
      },
      {
        exact: true,
        name: "square-invoices",
        path: SQUARE_INVOICES_API_PATH,
        component: SquareInvoicesManagementPage,
      },
      {
        exact: true,
        name: "square-payments",
        path: SQUARE_PAYMENTS_API_PATH,
        component: SquarePaymentsManagementPage,
      },
      {
        exact: true,
        name: "square-locations",
        path: SQUARE_LOCATIONS_API_PATH,
        component: SquareLocationsManagementPage,
      },
      {
        exact: true,
        name: "user-management",
        path: USER_MANAGEMENT_ROUTE,
        component: UserManagementPageComponent
      },
      {
        exact: true,
        name: "vendors",
        path: VENDORS_API_PATH,
        component: VendorsManagementPage
      },
      {
        exact: true,
        name: "glass-abbreviations",
        path: GLASS_ABBREVIATIONS_API_PATH,
        component: GlassAbbreviationsPage
      },
      {
        exact: true,
        name: "callrail-records",
        path: CALLRAIL_LOGS_ROUTE,
        component: CallRailLogsPage
      },
      {
        exact: true,
        name: "job-reviews",
        path: JOB_REVIEWS_API_PATH,
        component: JobReviewsPage
      },
      {
        exact: true,
        name: "external-orders",
        path: EXTERNAL_PART_ORDERS_API_PATH,
        component: ExternalOrdersManagementPage
      },
      {
        exact: true,
        name: "tag-management",
        path: TAGS_API_PATH,
        component: TagsManagementPage
      },
      {
        exact: true,
        name: "notification-system-interface",
        path: NOTIFICATION_SYSTEM_INTERFACE_ROUTE,
        component: NotificationSystemInterface
      },
      {
        exact: true,
        name: "portal-app-settings",
        path: PORTAL_APP_SETTINGS_ROUTE,
        component: PortalAppSettingsPage
      },
      {
        exact: true,
        name: "sixt-emails",
        path: SIXT_EMAILS_ROUTE,
        component: SixtEmailsPage
      },
      {
        exact: true,
        name: "part-cancellations",
        path: PART_CANCELLATIONS_ROUTE,
        component: PartCancellationsPage
      },
    ],
  },
  {
    component: NotFoundPage,
  },
];

// all roles (could be outdated in the future)
// Tinter
// Detailer
// Driver
// ExternalUser
// CSR
// Technician
// Manager
// Administrator
// GlobalApplicationAdministrator
// "Tinter", "Detailer", "Driver", "ExternalUser", "CSR", "Technician", "Manager", "Administrator", "GlobalApplicationAdministrator"

export const SIDEBAR_ITEMS = [
  {
    name: "home",
    label: "Dashboard",
    route: INDEX_ROUTE,
    icon: <HomeOutlined />,
    rolesAllowed: [
      "Driver", "Technician", "CSR", "Manager", "Administrator", "GlobalApplicationAdministrator"
    ],
    rolesForbbiden: [
      "Kiosk"
    ]
  },
  {
    name: "notifications",
    label: "Notifications",
    route: NOTIFICATIONS_API_PATH,
    icon: <NotificationOutlined />,
    rolesForbbiden: [
      "Kiosk"
    ]
  },
  {
    name: "cash-ledger",
    label: "Cash Ledger",
    route: CASH_LEDGER_MANAGEMENT_API_PATH,
    icon: <MoneyCollectOutlined />,
    rolesForbbiden: [
      "Kiosk"
    ]
  },
  {
    name: "/square-items",
    label: "Square Integration",
    icon: <SettingOutlined />,
    rolesAllowed: [
      "Administrator", "GlobalApplicationAdministrator"
    ],
    rolesForbbiden: [
      "Kiosk"
    ],
    submenu: [
      {
        name: "square-invoices",
        label: "Square Invoices",
        route: SQUARE_INVOICES_API_PATH,
        icon: <DollarOutlined />,
      },
      {
        name: "square-payments",
        label: "Square Payments",
        route: SQUARE_PAYMENTS_API_PATH,
        icon: <BankOutlined />,
      },
      {
        name: "square-locations",
        label: "Square Locations",
        route: SQUARE_LOCATIONS_API_PATH,
        icon: <ShopOutlined />,
      },
    ]
  },
  {
    name: "job-reviews",
    label: "Jobs in Review",
    route: JOB_REVIEWS_API_PATH,
    icon: <FormOutlined />,
    rolesAllowed: [
      "Driver", "Technician", "CSR", "Manager", "Administrator", "GlobalApplicationAdministrator"
    ],
    rolesForbbiden: [
      "Kiosk"
    ]
  },
  {
    name: "/available-employees",
    label: "Staff Availability",
    icon: <SettingOutlined />,
    rolesAllowed: [
      "Technician", "CSR", "Manager", "Administrator", "GlobalApplicationAdministrator"
    ],
    rolesForbbiden: [
      "Kiosk"
    ],
    submenu: [
      {
        name: "available-technicians",
        label: "Available Techs",
        route: AVAILABLE_TECHNICIANS_ROUTE,
        icon: <RocketOutlined />,
      },
      {
        name: "available-csr-agents",
        label: "Available CSRs",
        route: AVAILABLE_CSR_AGENTS_ROUTE,
        icon: <RocketOutlined />,
      },
    ]
  },
  {
    name: "nags-details",
    label: "NAGS #'s Details",
    route: NAGS_DETAILS_OVERVIEW_ROUTE,
    icon: <NumberOutlined />,
    rolesForbbiden: [
      "Kiosk"
    ]
  },
  {
    name: "technician-jobs",
    label: "Technician Jobs",
    route: TECHNICIAN_JOBS_ROUTE,
    icon: <ToolOutlined />,
    rolesForbbiden: [
      "Kiosk"
    ]
  },
  {
    name: "part-cancellations",
    label: "Part Cancellations",
    route: PART_CANCELLATIONS_ROUTE,
    icon: <ExceptionOutlined />,
  },
  {
    name: "egr-leads",
    label: "EGR Leads",
    route: EGR_LEADS_ROUTE,
    icon: <ImportOutlined />,
    rolesAllowed: [
      "ExternalUser", "Technician", "CSR", "Manager", "Administrator", "GlobalApplicationAdministrator"
    ],
    rolesForbbiden: [
      "Kiosk"
    ]
  },
  {
    name: "sixt-emails",
    label: "Sixt E-Mails",
    route: SIXT_EMAILS_ROUTE,
    icon: <ImportOutlined />,
    rolesAllowed: [
      "ExternalUser", "Technician", "CSR", "Manager", "Administrator", "GlobalApplicationAdministrator"
    ],
    rolesForbbiden: [
      "Kiosk"
    ]
  },
  {
    name: "callrail-forms",
    label: "CallRail Forms",
    route: CALLRAIL_FORMS_ROUTE,
    icon: <ImportOutlined />,
    rolesAllowed: [
      "ExternalUser", "Technician", "CSR", "Manager", "Administrator", "GlobalApplicationAdministrator"
    ],
    rolesForbbiden: [
      "Kiosk"
    ]
  },
  {
    name: "intake-forms",
    label: "Jobs",
    route: INTAKE_FORMS_ROUTE,
    icon: <ReconciliationOutlined />,
    rolesAllowed: [
      "ExternalUser", "Technician", "CSR", "Manager", "Administrator", "GlobalApplicationAdministrator"
    ],
    rolesForbbiden: [
      "Kiosk"
    ]
  },
  {
    name: "glass-pickup",
    label: "Glass Pickup",
    route: GLASS_PICKUP_ROUTE,
    icon: <ReconciliationOutlined />,
    rolesForbbiden: [
      "Kiosk"
    ]
  },
  {
    name: "contractors",
    label: "Contractors",
    route: CONTRACTOR_ROUTE,
    icon: <ContactsOutlined />,
    rolesForbbiden: [
      "Kiosk"
    ]
  },
  {
    name: "parts-ordered",
    label: "Parts Ordered",
    route: EXTERNAL_PART_ORDERS_API_PATH,
    icon: <FormOutlined />,
    rolesAllowed: [
      "ExternalUser", "Driver", "Technician", "CSR", "Manager", "Administrator", "GlobalApplicationAdministrator"
    ],
    rolesForbbiden: [
      "Kiosk"
    ]
  },
  {
    name: "callrail-logs",
    label: "CallRail Records",
    route: CALLRAIL_LOGS_ROUTE,
    icon: <ContainerOutlined />,
    rolesAllowed: [
      "ExternalUser", "CSR", "Manager", "Administrator", "GlobalApplicationAdministrator"
    ],
    rolesForbbiden: [
      "Kiosk"
    ]
  },
  {
    name: "callrail-messages",
    label: "Messages",
    route: CALLRAIL_MESSAGES_ROUTE,
    icon: <MessageOutlined />,
    rolesAllowed: [
      "CSR", "Manager", "Administrator", "GlobalApplicationAdministrator"
    ],
    rolesForbbiden: [
      "Kiosk"
    ]
  },
  {
    name: "customers",
    label: "Customers",
    route: CUSTOMERS_API_PATH,
    icon: <TeamOutlined />,
    rolesAllowed: [
      "CSR", "Manager", "Administrator", "GlobalApplicationAdministrator"
    ],
    rolesForbbiden: [
      "Kiosk"
    ]
  },
  {
    name: "file-browser",
    label: "File Browser",
    route: FILE_BROWSER_API_PATH,
    icon: <FileOutlined />,
    rolesAllowed: [
      "CSR", "Manager", "Administrator", "GlobalApplicationAdministrator"
    ],
    rolesForbbiden: [
      "Kiosk"
    ]
  },
  {
    name: "/settings",
    label: "Settings",
    icon: <SettingOutlined />,
    rolesAllowed: [
      "Administrator", "GlobalApplicationAdministrator"
    ],
    rolesForbbiden: [
      "Kiosk"
    ],
    submenu: [
      {
        name: "notification-interface",
        label: "Notification Interface",
        route: NOTIFICATION_SYSTEM_INTERFACE_ROUTE,
        icon: <NotificationOutlined />,
      },
      {
        name: "price-sheets",
        label: "Price Sheets",
        route: PRICE_SHEET_MANAGEMENT_ROUTE,
        icon: <ProfileOutlined />,
      },
      {
        name: "portal-dashboards",
        label: "Portal Dashboards",
        route: DASHBOARD_MANAGEMENT_ROUTE,
        icon: <DashboardOutlined />,
      },
      {
        name: "chat-quick-responses",
        label: "Chat Quick Responses",
        route: CHAT_QUICK_RESPONSES_API_PATH,
        icon: <MessageOutlined />,
      },
      {
        name: "/user-management",
        label: "User Management",
        route: USER_MANAGEMENT_ROUTE,
        icon: <TeamOutlined />,
      },
      {
        name: "/account-admin-actions",
        label: "Admin Actions",
        route: ACCOUNT_ADMIN_ACTIONS_API_PATH,
        icon: <SettingOutlined />,
      },
      {
        name: "/vendors",
        label: "Glass Vendors",
        route: VENDORS_API_PATH,
        icon: <BlockOutlined />,
      },
      {
        name: "/account-settings",
        label: "Account Settings",
        route: SETTINGS_API_PATH,
        icon: <SettingOutlined />,
      },
      {
        name: "/data-settings",
        label: "Data Settings",
        icon: <TeamOutlined />,
        rolesAllowed: [
          "Administrator", "GlobalApplicationAdministrator"
        ],
        submenu: [
          {
            name: "shop-locations",
            label: "Shop Locations",
            route: SHOP_LOCATIONS_API_PATH,
            icon: <ShopOutlined />,
          },
          {
            name: "tint-pricings",
            label: "Tint Pricings",
            route: TINT_PRICINGS_API_PATH,
            icon: <DollarOutlined />,
          },
          {
            name: "insurance-carriers",
            label: "Insurance Carriers",
            route: INSURANCE_CARRIERS_API_PATH,
            icon: <AuditOutlined />,
          },
          {
            name: "glass-abbreviations",
            label: "Glass Abbreviations",
            route: GLASS_ABBREVIATIONS_API_PATH,
            icon: <EditOutlined />,
          },
          {
            name: "tag-management",
            label: "Tags",
            route: TAGS_API_PATH,
            icon: <EditOutlined />,
          },
        ],
      },
      {
        name: "/portal-health",
        label: "Portal Health Metrics",
        route: PORTAL_HEALTH_ROUTE,
        icon: <CodepenOutlined />,
      }
    ]
  },
  {
    name: "/settings",
    label: "Settings",
    icon: <SettingOutlined />,
    rolesAllowed: [
      "CSR", "Manager"
    ],
    rolesForbbiden: [
      "Administrator", "GlobalApplicationAdministrator", "Kiosk"
    ], // both allowed and forbidden are present so we can show only one if user has multiple roles
    submenu: [
      {
        name: "chat-quick-responses",
        label: "Chat Quick Responses",
        route: CHAT_QUICK_RESPONSES_API_PATH,
        icon: <MessageOutlined />,
      },
      {
        name: "/data-settings",
        label: "Data Settings",
        icon: <TeamOutlined />,
        rolesAllowed: [
          "CSR", "Manager"
        ],
        submenu: [
          {
            name: "price-sheets",
            label: "Price Sheets",
            route: PRICE_SHEET_MANAGEMENT_ROUTE,
            icon: <ProfileOutlined />,
          },
          {
            name: "tint-pricings",
            label: "Tint Pricings",
            route: TINT_PRICINGS_API_PATH,
            icon: <DollarOutlined />,
          },
          {
            name: "insurance-carriers",
            label: "Insurance Carriers",
            route: INSURANCE_CARRIERS_API_PATH,
            icon: <AuditOutlined />,
          },
          {
            name: "glass-abbreviations",
            label: "Glass Abbreviations",
            route: GLASS_ABBREVIATIONS_API_PATH,
            icon: <EditOutlined />,
          },
          {
            name: "tag-management",
            label: "Tags",
            route: TAGS_API_PATH,
            icon: <EditOutlined />,
          },
        ],
      },
    ]
  },
  {
    name: "/settings",
    label: "Settings",
    icon: <SettingOutlined />,
    rolesAllowed: [
      "ExternalUser", "Tinter"
    ],
    rolesForbbiden: [
      "Administrator", "GlobalApplicationAdministrator", "CSR", "Manager", "Administrator", "GlobalApplicationAdministrator", "Kiosk"
    ], // both allowed and forbidden are present so we can show only one if user has multiple roles
    submenu: [
      {
        name: "chat-quick-responses",
        label: "Chat Quick Responses",
        route: CHAT_QUICK_RESPONSES_API_PATH,
        icon: <MessageOutlined />,
      },
    ]
  },
  {
    label: "Google Calendar",
    route: "https://calendar.google.com/calendar/",
    icon: <CalendarOutlined />,
    isExternalLink: true,
    rolesAllowed: [
      "CSR", "Manager", "Administrator", "GlobalApplicationAdministrator"
    ],
    rolesForbbiden: [
      "Kiosk"
    ]
  },
  {
    label: "Customer Check-In",
    route: `${process.env.REACT_APP_FRONTEND_CUSTOMER_FACING_URL}/check-in`,
    icon: <FieldTimeOutlined />,
    isExternalLink: true,
    rolesAllowed: [
      "CSR", "Manager", "Administrator", "GlobalApplicationAdministrator"
    ],
    rolesForbbiden: [
      "Kiosk"
    ]
  },
  {
    label: "Portal App Settings",
    route: PORTAL_APP_SETTINGS_ROUTE,
    icon: <SettingOutlined />
  }
];
