import { CUSTOMER_PREPAID_STATUS_VALUES, GLASS_STATUS_VALUES, INSTALL_STATUS_VALUES, NET_TERM_VALUES, PAYMENT_STATUS_VALUES, SALE_STAGE_VALUES } from "../../global_constants"

export const jobTypeButtonCheckboxOptions = [
  "Glass", "Chip Repair", "Body & Paint", "Tint Only", "Vehicle Detail"
]

export const defaultPercentages = [
  "5%", "10%", "15%", "20%", "30%"
]

export const fields = [
  {
    "label": "CallRail Form Record",
    "required": false,
    "disabled": true,
    "name": "egr_lead",
    "type": "egr_lead",
    "colspan": 24
  },
  {
    "label": "EGR Lead Record",
    "required": false,
    "disabled": true,
    "name": "created_by_callrail_form",
    "type": "created_by_callrail_form",
    "colspan": 24
  },
  {
    "label": "Split Job Info",
    "required": false,
    "disabled": true,
    "name": "split_job_info",
    "type": "split_job_info",
    "colspan": 24
  },
  {
    "label": "Warranty Info",
    "required": false,
    "disabled": true,
    "name": "warranty_info",
    "type": "warranty_info",
    "colspan": 24
  },
  {
    "label": "Job Identifier Reference",
    "required": false,
    "disabled": true,
    "name": "job_reference",
    "type": "text",
    "colspan": 24
  },
  {
    "label": "CSR",
    "required": true,
    "name": "csr_user_id",
    "type": "ddl",
    "options": [],
    "colspan": 12
  },
  {
    "label": "CSR 2",
    "required": false,
    "name": "csr_2_user_id",
    "type": "ddl",
    "clearable": true,
    "options": [],
    "colspan": 12
  },
  {
    "label": "Tags",
    "name": "tags",
    "type": "intakeFormTags",
    "colspan": 24
  },
  {
    "label": "Job Location",
    "required": true,
    "name": "shop_location_id",
    "type": "radio",
    "colspan": 24
  },
  {
    "label": "Sale Stage",
    "required": true,
    "name": "sale_stage",
    "type": "ddl",
    "options": SALE_STAGE_VALUES,
    "colspan": 8,
  },
  {
    "label": "Glass Status",
    "required": true,
    "name": "glass_status",
    "type": "ddl",
    "options": GLASS_STATUS_VALUES,
    "colspan": 8,
    "defaultValue": "GlassNotOrdered"
  },
  {
    "label": "Install Status",
    "required": true,
    "name": "install_status",
    "type": "ddl",
    "options": INSTALL_STATUS_VALUES,
    "colspan": 8,
    "defaultValue": "InstallationNotStarted"
  },
  {
    "label": "Payment Status",
    "required": false,
    "name": "payment_status",
    "type": "ddl",
    "options": PAYMENT_STATUS_VALUES,
    "colspan": 24,
    "defaultValue": "PaymentRequired"
  },
  {
    "label": "Is Invoice sent to Insurance/Vendor?",
    "required": false,
    "name": "is_invoice_sent_to_insurance_or_vendor",
    "type": "checkbox",
    "colspan": 24,
  },
  {
    "label": "Quote Rejected Reason",
    "required": true,
    "name": "quote_rejected_reason",
    "type": "quote_rejected_reason_object",
    "colspan": 24,
  },
  {
    "label": "Customer Cancelled Reason",
    "required": false,
    "name": "customer_cancelled_reason",
    "type": "textarea",
    "colspan": 24,
  },
  {
    "label": "Status History",
    "type": "status-history",
    "colspan": 24,
    "extra": [
      {
        "panelHeaderLabel": "Sale Stage",
        "key": "sale_stage",
        "objectKey": "status_history_sale_status"
      },
      {
        "panelHeaderLabel": "Install Status",
        "key": "install_status",
        "objectKey": "status_history_install_status"
      },
      {
        "panelHeaderLabel": "Payment Status",
        "key": "payment_status",
        "objectKey": "status_history_payment_status"
      },
      {
        "panelHeaderLabel": "Glass Status",
        "key": "glass_status",
        "objectKey": "status_history_glass_status"
      },
      {
        "panelHeaderLabel": "Legacy History",
        "key": "legacy_status_history",
        "objectKey": "legacy_status_history"
      }
    ]
  },
  {
    "label": "Contractors",
    "type": "contractor",
    "colspan": 24
  },
  {
    "label": "Customer Info",
    "type": "customer-info-heading-button",
    "colspan": 24
  },
  {
    "type": "panel-grouper",
    "label": "Customer Details",
    "name": "customer_details",
    "colspan": 24,
    "children": [
      {
        "label": "Customer (please search in following format: <first name>, <last name> (in that order) or <company name>)",
        "type": "ddl-debounce-select-customer-info-search",
        "name": "customer_info_json",
        "colspan": 24,
      },
      {
        "label": "Overwrite current customer data? This will also update customer info on previous job forms.",
        "type": "overwrite-customer-data-switch",
        "name": "overwrite_customer_data",
        "colspan": 24,
      },
      {
        "label": "First Name",
        "required": true,
        "name": "first_name",
        "type": "text",
        "colspan": 11
      },
      {
        "label": "",
        "type": "flipButton",
        "colspan": 2
      },
      {
        "label": "Last Name",
        "required": true,
        "name": "last_name",
        "type": "text",
        "colspan": 11
      },
      {
        "label": "Company Name",
        "required": true,
        "name": "company_name",
        "type": "text",
        "colspan": 24
      },
      {
        "label": "Is A Company?",
        "required": true,
        "name": "is_company",
        "type": "switch",
        "colspan": 24
      },
      {
        "label": "Address",
        "required": true,
        "name": "address",
        "type": "text",
        "colspan": 24
      },
      {
        "label": "City",
        "required": true,
        "name": "city",
        "type": "text",
        "colspan": 10
      },
      {
        "label": "State",
        "required": true,
        "name": "state",
        "type": "text",
        "colspan": 10,
      },
      {
        "label": "ZIP Code",
        "required": true,
        "name": "zip_code",
        "type": "text",
        "colspan": 4
      },
      {
        "label": "Calculate Mobile Distance",
        "type": "calculateMobileDistanceButton",
        "colspan": 24
      },
      {
        "label": "Mobile Distance (miles)",
        "required": false,
        "name": "mobile_distance",
        "type": "mobileDistanceNumber",
        "colspan": 8
      },
      {
        "label": "Mobile Duration",
        "required": false,
        "name": "mobile_time",
        "type": "mobileDuration",
        "colspan": 8
      },
      {
        "type": "emptySpace",
        "colspan": 8
      },
      {
        "label": "Telephone",
        "required": true,
        "name": "telephone",
        "type": "phoneNumber",
        "colspan": 24
      },
      {
        "label": "Email",
        "required": false,
        "name": "email",
        "type": "text",
        "colspan": 24
      },
      {
        "label": "Customer Status",
        "type": "heading",
        "colspan": 24
      },
      {
        "label": "Is Customer Treated as VIP on this Job?",
        "required": false,
        "name": "is_vip",
        "type": "checkbox",
        "colspan": 24
      },
    ]
  },
  {
    "label": "Callback Info",
    "type": "callback-info-component",
    "colspan": 24,
  },
  {
    "label": "Add New Vehicles",
    "type": "add-new-vehicle",
    "colspan": 24,
  },
  {
    "label": "Add a Vehicle",
    "type": "add-vehicle-component",
    "colspan": 24,
  },
  {
    "label": "Vehicle Search (please search in following format: year, make, model (in that order))",
    "type": "ddl-debounce-select-vehicle-search",
    "desc": "vehicle-search",
    "colspan": 24,
  },
  {
    "label": "Quick Quote",
    "required": false,
    "type": "quick_quote",
    "colspan": 24
  },
  {
    "label": "Payment Method",
    "required": true,
    "name": "work_type",
    "type": "radio",
    "options": [
      {
        "value": "Cash",
        "label": "Cash"
      },
      {
        "value": "Insurance",
        "label": "Insurance"
      },
      {
        "value": "Warranty",
        "label": "Warranty"
      },
    ],
    "colspan": 12,
  },
  {
    "type": "emptySpace",
    "colspan": 12
  },
  {
    "label": "Warranty - Original Job",
    "type": "warranty_original_job_selection",
    "colspan": 24,
  },
  {
    "label": "Insurance Carrier",
    "required": false,
    "name": "insurance_carrier_id",
    "type": "ddl",
    "options": [],
    "colspan": 8,
  },
  {
    "label": "Referral/Claim/Dispatch#",
    "required": false,
    "name": "insurance_referral_claim_dispatch_number",
    "type": "text",
    "colspan": 8,
  },
  {
    "label": "Deductible",
    "required": false,
    "name": "deductible",
    "type": "currency",
    "colspan": 8,
  },
  {
    "label": "Insurance Photos Upload",
    "type": "heading",
    "colspan": 24
  },
  {
    "type": "insurance-photos-upload",
    "colspan": 24,
  },
  {
    "label": "Insurance Files Upload",
    "type": "heading",
    "colspan": 24
  },
  {
    "type": "insurance-files-upload",
    "colspan": 24,
  },
  {
    "label": "Insurance Pricing",
    "type": "insurance_pricing_component",
    "colspan": 24,
  },
  {
    "label": "Job Parts",
    "type": "heading",
    "colspan": 24
  },
  {
    "label": "Job Type (select one or more)",
    "required": true,
    "type": "tags",
    "options": jobTypeButtonCheckboxOptions,
    "colspan": 24
  },
  {
    "label": "Glass Replacement",
    "type": "job-type-heading",
    "jobType": "Glass",
    "colspan": 24
  },
  {
    "label": "Glass Replacement object",
    "type": "job-type-object",
    "jobTypeRequired": "Glass",
    "colspan": 24
  },
  {
    "label": "Chip Repair",
    "type": "job-type-heading",
    "jobType": "Chip Repair",
    "colspan": 24
  },
  {
    "label": "Chip Repair object",
    "type": "job-type-object",
    "jobTypeRequired": "Chip Repair",
    "colspan": 24
  },
  {
    "label": "Body & Paint",
    "type": "job-type-heading",
    "jobType": "Body & Paint",
    "colspan": 24
  },
  {
    "label": "Body & Paint object",
    "type": "job-type-object",
    "jobTypeRequired": "Body & Paint",
    "colspan": 24
  },
  {
    "label": "Tint Only",
    "type": "job-type-heading",
    "jobType": "Tint Only",
    "colspan": 24
  },
  {
    "label": "Tint Only object",
    "type": "job-type-object",
    "jobTypeRequired": "Tint Only",
    "colspan": 24
  },
  {
    "label": "Vehicle Detail",
    "type": "job-type-heading",
    "jobType": "Vehicle Detail",
    "colspan": 24
  },
  {
    "label": "Vehicle Detail object",
    "type": "job-type-object",
    "jobTypeRequired": "Vehicle Detail",
    "colspan": 24
  },
  {
    "label": "Mobile Fee $",
    "name": "mobile_fee",
    "required": false,
    "type": "currency",
    "colspan": 24
  },
  {
    "label": "Sale Price",
    "type": "price-total",
    "colspan": 24
  },
  {
    "label": "Discount",
    "type": "discount-amount",
    "colspan": 24
  },
  {
    "label": "Total",
    "type": "calculated-total-price",
    "colspan": 8
  },
  {
    "label": "Deposit Amount",
    "name": "deposit_amount",
    "disabled": true,
    "type": "currency",
    "colspan": 16
  },
  {
    "label": "Discount Amount",
    "name": "discount_amount",
    "type": "currency",
    "colspan": 24,
  },
  {
    "label": "Select Percentage (select one)",
    "name": "discount_percentage_tag",
    "type": "tags",
    "options": defaultPercentages,
    "multiple": false,
    "colspan": 8
  },
  {
    "label": "Discount Percentage",
    "name": "discount_percentage",
    "type": "percentage",
    "colspan": 16,
  },
  {
    "label": "Tips",
    "name": "tips",
    "required": false,
    "type": "currency",
    "colspan": 24
  },
  {
    "label": "Deposit Management",
    "type": "heading",
    "colspan": 24
  },
  {
    "label": "Deposit Management",
    "type": "deposit_management_component",
    "colspan": 24,
  },
  {
    "label": "Installation Info",
    "type": "heading",
    "colspan": 24
  },
  {
    "label": "Installation Time",
    "required": false,
    "type": "nags_installation_time",
    "colspan": 12,
  },
  {
    "type": "emptySpace",
    "colspan": 12
  },
  {
    "label": "Customer Prepaid Status",
    "required": false,
    "clearable": true,
    "name": "customer_prepaid_status",
    "type": "ddl",
    "options": CUSTOMER_PREPAID_STATUS_VALUES,
    "colspan": 12,
  },
  {
    "type": "emptySpace",
    "colspan": 12
  },
  {
    "label": "Technician",
    "required": false,
    "name": "technician_user_id",
    "type": "ddl",
    "options": [],
    "colspan": 24
  },
  {
    "label": "Job Notes",
    "required": false,
    "name": "job_notes",
    "type": "textarea",
    "colspan": 24,
    "isAddOnly": true,
  },
  {
    "label": "Job Notes",
    "type": "job_notes_existing_job",
    "colspan": 24,
    "isEditOnly": true,
  },
  {
    "label": "Appointment Time",
    "required": false,
    "name": "appointment_time",
    "type": "datetime",
    "colspan": 24
  },
  {
    "label": "Emergency Job?",
    "required": false,
    "type": "emergencyJobCheckbox",
    "colspan": 24
  },
  {
    "label": "Emergency Job Fee",
    "required": false,
    "name": "emergency_service_fee",
    "type": "currency",
    "colspan": 24
  },
  {
    "label": "Extra Job Pay (Technician)",
    "required": false,
    "name": "extra_job_pay",
    "type": "currency",
    "colspan": 24
  },
  {
    "label": "Net Terms",
    "required": false,
    "name": "net_term",
    "type": "ddl",
    "options": NET_TERM_VALUES,
    "colspan": 24,
    "defaultValue": "OnReceipt"
  },
  {
    "type": "generic-photo-upload",
    "colspan": 24,
  },
  {
    "label": "Waivers",
    "type": "heading",
    "colspan": 24
  },
  {
    "label": "Waivers",
    "required": false,
    "type": "liability-waivers",
    "colspan": 24
  },
]
